import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { getAssetUrl } from '../services/cdn'
import { Container as BlockContainer } from '../components/Blocks'
import { HeroVideo, CoreValues } from '../components/Sections'
import { Container, Row, Col } from 'reactstrap'
import styled from 'styled-components'

const Block = styled.div`
  height: 55vh;
  @media screen and (min-width: 1800px) {
    height: 80vh;
  }
  @media screen and (min-width: 2600px) {
    height: 100vh;
  }
`;

const IndexPage = () => (
  <Layout>
    <SEO title="Chairman's Message" keywords={[`gatsby`, `application`, `react`]} />
    <HeroVideo id="main-hero" x={50} y={50} height={null} src="app/Video/anniversary.mp4" posterSrc={getAssetUrl('app/video-poster.png')} loop={true} autoplay muted>
    {/* <HeroVideo id="main-hero" height={null} x={50} y={50} src="app/Video/"  posterSrc={getAssetUrl('app/ASEAN_Banner.jpg')} loop={true} autoplay muted> */}
      <Block />
    </HeroVideo>
    <BlockContainer padding={0} className="pt-5">
      <Container>
        <Row>
          <Col md="12">
              <h1 className="mb-3">Chairperson's Message</h1>
          </Col>
        </Row>
      </Container>
    </BlockContainer>
    <BlockContainer padding={3} center={false}>
      <Container>
        <div className="lead d-flex flex-column justify-content-center align-items-center">
          <img className="img-fluid d-block mx-auto mb-3" style={{ height: '300px'}} src={getAssetUrl('app/directors/Doc-Angelito-Bagui.jpg')} alt="CHAIRPERSON" />
          <p><b>Chairperson of the Board</b></p>
          <p><b>Dr. Angelito B. Bagui</b></p>
        </div>
        <Row>
          <Col sm="12">
            <div className="mt-3 text-justify">
              <h4>Welcome to SIDC Website!</h4>
              <p>For more five decades, SIDC has transformed from a simple "sari-sari" store to a billionaire cooperative. From 59 farmer incorporators, it has grown to more than 72,000 members.</p>
              <p>Today, we are continuing to evolve as an internationally competitive and diverse business and social enterprise with activities ranging from manufacturing to agriculture, retail, lending, tourism, and other services. This website is a testament to all our endeavours. Journey with us as we try to inspire, inform, develop and connect with people sharing our vision around the globe.</p>
              <p>Our aim is to empower stakeholders for sustainable progressive life while strengthening their spiritual and social development. We are committed to quality while upholding core values like trust in God and integrity.</p>
              <p>Thru this website, we hope to create a virtual community which will make business faster, and our culture more open and transparent. Your comments and feedback will be greatly appreciated. Surf for SIDC!</p>
            </div>
          </Col>
        </Row>
      </Container>
    </BlockContainer>
    <CoreValues />
  </Layout>
)

export default IndexPage
